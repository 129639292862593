.developers-resources {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-columns: 70% auto;
    column-gap: 30px;
  }

  &__grid {
    order: 2;

    @media (min-width: 992px) {
      order: 1;
    }
  }

  &__filters {
    order: 1;

    @media (min-width: 992px) {
      order: 2;
    }
  }
}

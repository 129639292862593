.developers-resources-grid {
  display: grid;
  // grid-template-columns: 1fr 1fr;

  // grid-template-columns: repeat(1, minmax(0, 1fr));

  column-gap: 1em;
  row-gap: 1em;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__item {
    display: flex;
    align-items: stretch;
  }
}

.resources-section {
  &__carousel-container {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &__grid-container {
    display: none;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: repeat(2, 1fr);
    }
  }
}

.changelog {
  margin-bottom: 37px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  h3.changelog__title {
    font-size: 2rem;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
  }

  &__description {
    font-size: 1.125rem;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      margin-bottom: 48px;
    }
  }

  &__cta {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

.document-item {
  &__header {
    margin-bottom: 20px;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @media (min-width: 768px) {
      margin-bottom: 29px;
      padding-bottom: 30px;
    }
  }

  h3.document-item__title {
    font-size: 1.5rem;
    margin-bottom: 0;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  a.document-item__cta {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    @media (max-width: 768px) {
      padding: 12px !important;
    }

    span {
      display: none;
      margin-right: 10px;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  &__description {
    font-size: 1.125rem;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }
}

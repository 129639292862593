.developers-featured-resources-list-item {
  color: var(--body-text);
  position: relative;
  display: block;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--body-text);
    }
  }

  &__header {
    display: flex;
    flex: 1 1 0%;
    gap: 1em;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 0;
    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;

    font-size: 1.75rem;
    line-height: 118%;
  }
  &__cta {
    @media (max-width: 992px) {
      display: none;
    }
  }
}

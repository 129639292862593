.content-container {
  display: flex;
  flex-direction: column;
  background: #19161c;
  box-shadow: 0px 58px 80px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 32px 28px;

  @media (min-width: 768px) {
    padding: 53px 54px 45px 55px;
  }

  h3.content-title {
    font-size: 1.75rem;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 2.25rem;
      margin-bottom: 24px;
    }
  }

  p.content-description {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
}

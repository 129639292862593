.iterable-email-subscribe-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  &__input {
    height: 65px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border: none;
    color: #fff;
    background-color: #0a0a0a;

    &:focus {
      background-color: #26262b;
      color: #fff;
    }

    &::placeholder {
      color: #fff;
      opacity: 0.5;
    }
  }

  &__input-error {
    color: red;
  }

  &__button-container {
    padding: 10px;

    @media (min-width: 480px) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__button {
    height: 46px;
    min-width: 101px;
  }
}

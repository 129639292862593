/*
    Custom styles to be applied to the CodeDocBlock pages (e.g. RPC docs)
*/

/* .DocSideBySide {
  margin-top: 2rem;
} */

.DocLeftSide {
  display: block;
  width: 100%;
  font-size: 18px;
}

.DocRightSide {
  display: block;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .DocSideBySide {
    display: flex;
    width: 100%;
  }
  .DocLeftSide {
    margin-right: 3rem;
    width: calc(50% - 1.5rem);
  }
  .DocRightSide {
    width: calc(50% - 1.5rem);
  }
}

.Parameter {
  margin-bottom: 1em;
  padding-top: 0.75em;
  border-top: 1px solid #1e1e1e;
}
.Parameter:last-of-type {
  border-bottom: 1px solid #1e1e1e;
}

.Parameter details {
  font-size: 16px;
}

.ParameterName {
  font-weight: 700;
}

.ParameterHeader {
  font-family: mono;
  padding: 0.1em 0em;
}

.Field {
  /* // padding: 1em 0em; */
  margin: 1em 0em 1em 1em;
  /* border-left: 1px solid #1e1e1e; */
}
.Field section {
  padding: 0em 1em;
}

.FlagItem {
  /* display: block; */
  margin: 0 0.5rem;
  color: #848484;
  font-weight: 600;
  font-size: 14px;
}

.Heading {
  font-size: 1.24rem;
  font-weight: 700;
}
.SubHeading {
  /* font-size: 1.24rem; */
  font-weight: 600;
}

.resource-item {
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 297px !important;
  min-height: 300px;
  background: #19161c;
  border-radius: 12px;
  padding: 32px;
  border-bottom: 2px solid transparent;

  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 0.3s ease opacity;
    transform: translateX(-50%);
    bottom: -45%;
    left: 50%;
    background: radial-gradient(#9945ff, transparent 70%);
    width: 100%;
    height: 175px;
    border-radius: 50%;
  }

  &:hover {
    border-color: #522b81;

    &::before {
      opacity: 1;
    }
  }

  &__container {
    height: 100%;
  }

  &__category {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.2rem;
    letter-spacing: 0.125em;
    text-transform: uppercase;
    color: var(--mdx-link-color);
    margin-bottom: 10px;
  }

  &__title-container {
    max-height: 34px;

    h3.resource-item__title {
      color: #fff;
      font-size: 1.2rem;

      // line clamp tp 2 lines
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &__description {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #fff;
    margin-top: 10px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  &__cta {
    display: flex;
    color: #ffffff;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    span {
      margin-right: 8px;
    }
  }
}

.container {
  @media (min-width: 768px) {
    padding: 53px 55px 54px 55px;
  }
}

.other-content-links {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media (min-width: 768px) {
    max-height: 44px;
    margin-top: 64px;
    flex-direction: row;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    background-color: transparent !important;
    white-space: pre;

    @media (min-width: 768px) {
      margin-right: 22px;
    }

    &:hover {
      background-color: #fff !important;
    }

    &:focus {
      background-color: transparent !important;
      color: #fff !important;
      border-color: #fff !important;
    }
  }

  a:not(:first-child) {
    @media (max-width: 768px) {
      margin-top: 22px;
    }
  }
}
